/**
 * MDTab component styles.
 */
import { Styles } from 'react-jss';

export default {
  mdpage: {
    paddingBottom: '10rem',
  },
} as Styles;
